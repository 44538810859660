import { useEffect, useState, useContext } from "react";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { GlobalContext } from "../../../GlobalService";
import "../cusAcknowlegment/AckForm/AckForm.scss";
import { CDA_SPECIFIC_DISCLOSURE } from "../../../common/AppConstants";


const GMSupplierDiscount = ({
                                discountProgram,
                                setDiscountProgram,
                                priceLevel,
                                acknowledgementData,
                                hasAuthCode,
                                pricingForAll,
                                formulaPricing
                            }) => {

    const tableData = discountProgram;
    
    const [isError, setIsError] = useState({
        amountTwo: false,
        amountThree: false,
        amountFour: false,
        amountFive: false,
    })
    const {initialURL, completeForm, setDisableInitials} = useContext(GlobalContext);

    const fontColor = {
        style: {color: "rgb(50, 50, 50)"},
    };

    const authApprovalStyle = {
        style: {color: "rgb(50, 50, 50)", border: "none"},
    };

    const authApprovalStyleError = {
        style: {border: "2px solid #E50000", color: "#E50000"},
    };

    /**
     * Check if number is a string or not
     * @param {*} num
     * @returns 0 if the num is a string and
     * returns the original number if it's an
     * int
     */
    function checkNumber(num) {
        let number = parseFloat(num?.replace(/[$,]/g, ""));
        if (isNaN(number)) {
            return 0;
        } else {
            return number;
        }
    }

    useEffect(() => {
        if (priceLevel === "SUP") {
            discountProgram.amountOne = acknowledgementData.supplierPrice.toString();
            tableData.amountOne = acknowledgementData.supplierPrice.toString();
        } else if (priceLevel === "EMP") {
            discountProgram.amountOne = acknowledgementData.empPrice.toString();
            tableData.amountOne = acknowledgementData.empPrice.toString();
        } else if (priceLevel === "QRD")
            discountProgram.amountOne = acknowledgementData.msrpPrice.toString();
    }, []);

    /**
     * Calculates the total amount for the last column
     */
    useEffect(() => {
        let totalAmount =
            checkNumber(discountProgram.amountOne) +
            checkNumber(tableData.amountTwo) +
            checkNumber(tableData.amountThree) +
            checkNumber(tableData.amountFour) +
            checkNumber(tableData.amountFive)+
            checkNumber(tableData.amountSix);
        
        //TODO: Check if this is necessary since checkNumber is called for all other numbers
        if (isNaN(totalAmount)) {
            setDiscountProgram({
                ...tableData,
                totalAmount: "",
            });
        } else {
            setDiscountProgram({
                ...tableData,
                totalAmount: totalAmount,
            });
        }
    }, [
        tableData.amountOne,
        tableData.amountTwo,
        tableData.amountThree,
        tableData.amountFour,
        tableData.amountFive,
        tableData.amountSix
    ]);

    useEffect(() => {
        if(priceLevel !== "NON" && (hasAuthCode || pricingForAll === "Y" || formulaPricing)) {
            if(initialURL !== null){
                setDisableInitials(false);
            } else {
                setDisableInitials(true);
            }
        } else {
            setDisableInitials(false);
        }
    }, [discountProgram, initialURL]);
 
    return (
        <div className="group-wrapper">
            <div className="input-field-header">
                Customer Dealer Agreement Details
            </div>
            <table className="acknowledgment-table" bordercolor="0072CE">
                <thead>
                <tr>
                    <th>Description</th>
                    <th className="medium-column">Items or Charge Types</th>
                    <th className="small-column">Purchaser's <br/>Initials</th>
                    <th className="small-column">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        1. For the GM Discount Program enter the EMPLOY or SUPPLR discount amount from the invoice
                        as applicable.
                    </td>
                    <td>
                        <TextField
                            id="row1-items-box"
                            className="req-field"
                            variant="standard"
                            value={discountProgram.itemOne !== " " ? discountProgram.itemOne : "N/A"}
                            multiline
                            name="itemOne"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            inputProps={{maxLength: 20}}
                            size="small"
                        ></TextField>
                    </td>
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row1-initials-box"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                disabled
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"
                                />
                            </div>}
                    </td>
                    <td className="amount-cell">
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.amountOne}
                            name="amountOne"
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            prefix={"$"}
                            variant="outlined"
                            size="small"
                            disabled
                            style={{backgroundColor: "white"}}
                            inputProps={(discountProgram.amountOne == ""
                                || discountProgram.amountOne == null)
                                ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        2. Negotiated discount for prior model year or
                        Demo discount, etc.
                    </td>
                    <td>
                        <TextField
                            id="row2-items-box"
                            placeholder="Required"
                            className="req-field"
                            variant="standard"
                            value={discountProgram.itemTwo}
                            multiline
                            name="itemTwo"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            size="small"
                            inputProps={(discountProgram.itemTwo == ""
                                || discountProgram.itemTwo == null)
                                ? authApprovalStyleError : {maxLength: 20}}
                        ></TextField>
                    </td>
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row2-initials-box"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                disabled
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"
                                />
                            </div>}
                    </td>
                    <td className="amount-cell">
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.amountTwo}
                            name="amountTwo"
                            thousandSeparator={true}
                            prefix={"-$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            variant="outlined"
                            size="small"
                            disabled
                            allowNegative={false}
                            style={{backgroundColor: "white"}}
                            inputProps={(discountProgram.amountTwo == ""
                                || discountProgram.amountTwo == null)
                                ? authApprovalStyleError : authApprovalStyle}
                            InputProps={isError.amountTwo ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        3. Plus Itemized Adds/Deducts NOT LISTED on Buyer's Order.
                    </td>
                    <td>
                        <TextField
                            id="row3-items-box"
                            placeholder="Required"
                            className="req-field"
                            variant="standard"
                            value={discountProgram.itemThree}
                            multiline
                            name="itemThree"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            size="small"
                            inputProps={(discountProgram.itemThree == ""
                                || discountProgram.itemThree == null)
                                ? authApprovalStyleError : {maxLength: 20}}
                        ></TextField>
                    </td>
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row3-initials-box"
                                disabled
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"

                                />
                            </div>}
                    </td>
                    <td className="amount-cell">
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.amountThree}
                            name="amountThree"
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            disabled
                            variant="outlined"
                            size="small"
                            style={{backgroundColor: "white"}}
                            inputProps={(discountProgram.amountThree == ""
                                || discountProgram.amountThree == null)
                                ? authApprovalStyleError : authApprovalStyle}
                            InputProps={isError.amountThree ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        4. Trade-in over-allowance or remaining
                        payments/charges from prior lease.
                    </td>
                    <td>
                        <TextField
                            id="row4-items-box"
                            placeholder="Required"
                            className="req-field"
                            variant="standard"
                            value={discountProgram.itemFour}
                            multiline
                            name="itemFour"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            size="small"
                            inputProps={((discountProgram.itemFour == ""
                                || discountProgram.itemFour == null)
                                ? authApprovalStyleError : {maxLength: 20})}
                        ></TextField>
                    </td>
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row4-initials-box"
                                disabled
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"
                                />
                            </div>}
                    </td>
                    <td className="amount-cell">
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.amountFour}
                            name="amountFour"
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            variant="outlined"
                            disabled
                            size="small"
                            style={{backgroundColor: "white"}}
                            inputProps={(discountProgram.amountFour == ""
                                || discountProgram.amountFour == null)
                                ? authApprovalStyleError : authApprovalStyle}
                            InputProps={isError.amountFour ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        5. Charges for any GM provided service agreed to by
                        customer.
                    </td>
                    <td>
                        <TextField
                            placeholder="Required"
                            className="req-field"
                            id="row2-items-box"
                            variant="standard"
                            value={discountProgram.itemFive}
                            multiline
                            name="itemFive"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            size="small"
                            inputProps={(discountProgram.itemFive == ""
                                || discountProgram.itemFive == null)
                                ? authApprovalStyleError : {maxLength: 20}}
                        ></TextField>
                    </td>
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row5-initials-box"
                                disabled
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"
                                />
                            </div>}
                    </td>
                    <td className="amount-cell">
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.amountFive}
                            name="amountFive"
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            variant="outlined"
                            disabled
                            size="small"
                            style={{backgroundColor: "white"}}
                            inputProps={(discountProgram.amountFive == ""
                                || discountProgram.amountFive == null)
                                ? authApprovalStyleError : authApprovalStyle}
                            InputProps={isError.amountFive ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                <tr>	
                    <td>	
                    <label htmlFor="row1-items-box">	
                        6. Additional Rebate and/or Discount Subject to Tax.	
                    </label>	
                    </td>	
                    <td>	
                        <TextField	
                            id="row1-items-box"	
                            className="req-field"	
                            variant="standard"
                            multiline	
                            value={tableData.itemSix !== " " ? discountProgram.itemSix : "N/A"}	
                            name="itemSix"	
                            InputProps={{	
                                disableUnderline: true,	
                            }}	
                            inputProps={{maxLength: 20}}	
                            size="small"	
                            
                        ></TextField>	
                    </td>	
                    <td>
                        {!completeForm ?
                            <TextField
                                variant="standard"
                                id="row5-initials-box"
                                disabled
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                size="small"
                            ></TextField> :
                            <div className="initial-container">
                                <img
                                    src={initialURL}
                                    alt="initials"
                                    className="initial-display"
                                />
                        </div>}	
                    </td>	
                    <td className="amount-cell">	
                        <NumberFormat	
                            placeholder="Required"
                            disabled={true}	
                            className="req-field"	
                            value={discountProgram.amountSix}	
                            name="amountSix"	
                            thousandSeparator={true}	
                            prefix={"$"}	
                            decimalScale={2}	
                            fixedDecimalScale={true}	
                            allowNegative={false}	
                            variant="outlined"	
                            size="small"	
                            style={{backgroundColor: "white"}}	
                            inputProps={(discountProgram.amountSix == ""	
                                || discountProgram.amountSix == null)	
                                ? authApprovalStyleError : authApprovalStyle}	
                            InputProps={isError.amountSix ? authApprovalStyleError : authApprovalStyle}	
                        />	
                    </td>	
                </tr>
                <tr className="total-row">
                    <td className="discount-last-row" colSpan="3">
                        7. Total of the amounts in the far-right column
                        should match the final sales price on the buyer's
                        order.
                    </td>
                    <td>
                        <NumberFormat
                            placeholder="Required"
                            className="req-field"
                            value={discountProgram.totalAmount}
                            name="amountSix"
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            disabled
                            InputProps={{
                                disableUnderline: true,
                            }}
                            inputProps={(discountProgram.totalAmount == ""
                                || discountProgram.totalAmount == null)
                                ? authApprovalStyleError : authApprovalStyle}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="cda-disclosure">
                {CDA_SPECIFIC_DISCLOSURE}
            </div>
            <div className="group-bottom">
            </div>
            <Divider/>
        </div>
    );
};

export default GMSupplierDiscount;
